import '../styles/index.scss';
import { scrollStart, scrollStop, resize } from './scrolling.js';
import copy from 'copy-to-clipboard';

window.onload = function () {

	let throttle = require('lodash.throttle');
	let debounce = require('lodash.debounce');

	/*
	  cors-proxy-servers
	  https://gist.github.com/jimmywarting/ac1be6ea0297c16c477e17f8fbe51347
	*/

	//let CORS_PROXY = 'https://www.reallysimplesatisfaction.com/reallysimpleproxy/?url=';
	let CORS_PROXY = 'https://www.stefanendres.com/dev/reallysimplesatisfaction/reallysimpleproxy/?url=';
	//let CORS_PROXY = 'http://localhost:8888/tests/reallysimpleproxy/?url=';

	// meta-data variables
	let page_title = document.querySelector('.page-title');
	let favicon = document.querySelector('.favicon');

	let og_description = document.querySelector('.og-description');
	let og_image = document.querySelector('.og-image');

	// ui variables
	let body = document.querySelector('body');
	let background = document.querySelector('.background');
	let heading = document.querySelector('h1');
	let paragraphs = document.querySelectorAll('p');

	let playlist = document.querySelector('.playlist-container');
	//let playlist_heading_note = document.querySelector('.playlist-heading__note');

	let scroll_index_h = document.querySelectorAll('.scroll-index');
	let shadow_bar_bottom = document.querySelector('.shadow-bar.bottom');
	let shadow_bar_top = document.querySelector('.shadow-bar.top');

	let loading_box = document.querySelector('.loading-box');
	let loading_spinner = document.querySelector('.loading-spinner');
	let loading_submit = document.querySelector('.loading-submit');

	let menu_wrapper = document.querySelector('.menu-wrapper');
	let menu_close_button = document.querySelector('.menu-option--close');
	let menu_mute_button = document.querySelector('.menu-option--mute');
	let menu_autoscroll_button = document.querySelector('.menu-option--autoscroll');
	let menu_reload_button = document.querySelector('.menu-option--reload');
	let menu_share_button = document.querySelector('.menu-option--share');

	body.style.minHeight = window.innerHeight + 'px';

	let container = document.createElement('div');
	container.className = 'container';
	body.appendChild(container);
	body.style.opacity = 1;
	menu_wrapper.style.display = 'block'; // avoid popping up on pageload

	// data variables
	let titles_array = [];
	let all_text_array = [];
	let sub_links_array = [];
	let image_src_array = [];
	let media_el_array = [];
	let audio_video_el_array = [];
	let audio_video_filname_array = [];
	let volume_level = 0.666;

	let starter_text_array = [
		'Discover the latest content of the web, unlimited and for free.',
		'Keep up with all the topics and trends that matter right now – all in one place.',
		'Never miss anything.',
		'Don’t cut the noise.',
		'Be in the know.',
		'Scroll down to dive in.'
	];

	// make sure scrollpos is 0 at start
	window.scrollTo(0, 0);

	// on mobile disable sound first, so the user has to interact and the sound can play
	if (Modernizr.touchevents) {
		menu_mute_button.classList.remove('active');
		volume_level = 0;
		// show muted note in playlist
		//playlist_heading_note.classList.add('visible');
	}


	// create worker for handling xhr-requests
	let request_worker = new Worker('./public/workers/request_worker.js');

	// start request-worker to get trends xml-data
	request_worker.postMessage({
		'type' : 'trends',
		'proxy' : CORS_PROXY,
		'url' : null // if url is null, urls from trends-array (listed listed in request_worker.js) are used
	});

	// create worker for handling ip-data and rgb-value
	let ip_worker = new Worker('./public/workers/ip_worker.js');

	// start ip-worker
	ip_worker.postMessage({});


	loading_submit.addEventListener('click', function () {

		console.log('start');

		paragraphs[1].classList.remove('starter');
		updateText(paragraphs[0], all_text_array[Math.floor(Math.random() * all_text_array.length)], -300, 150*6, Math.floor((Math.random() * 1000) + 500));
		//updateText(paragraphs[1], all_text_array[Math.floor(Math.random() * all_text_array.length)]+'<br>'+all_text_array[Math.floor(Math.random() * all_text_array.length)], 0, 150*4, Math.floor((Math.random() * 1000) + 500));
		updateText(paragraphs[1], starter_text_array[5], 0, 150*4, Math.floor((Math.random() * 1000) + 500));
		updateText(paragraphs[2], all_text_array[Math.floor(Math.random() * all_text_array.length)], 300, 150*2, Math.floor((Math.random() * 1000) + 500));

		container.style.display = 'flex';

		playlist.parentElement.style.display = 'block';

		loading_spinner.style.display = 'block';
		loading_submit.style.display = 'none';
		loading_box.style.fontSize = '0vw';

		// update background-image
		background.style.backgroundImage = 'url('+ image_src_array[Math.floor(Math.random() * image_src_array.length)] +')';

		setTimeout(function () {
			loading_spinner.style.display = 'none';
			loading_submit.style.display = 'block';

			if (!body.classList.contains('visible')) {
				body.classList.add('visible');
			}



		}, 5000);

		// append all avalable media to container and remove it from media-array
		let length;
		if (media_el_array.length >= 250) {
			length = 250;
		} else {
			length = media_el_array.length;
		}
		for (var i = length - 1; i >= 0; i--) {
			const r = Math.floor(Math.random() * Math.floor(media_el_array.length - 1));
			container.appendChild(media_el_array[r]);

			media_el_array.splice(media_el_array[r], 1);
			//console.log('media_el_array.length: ', media_el_array.length);
		}




				menu_mute_button.classList.add('active');
				volume_level = 1;

				/* un-/mute and play/pause videos and audios */
				for (var i = audio_video_el_array.length - 1; i >= 0; i--) {
					audio_video_el_array[i].volume = 1;
					audio_video_el_array[i].muted = false;
					if (audio_video_el_array[i].paused) {
						audio_video_el_array[i].play();
					} else {
						//audio_video_el_array[i].pause();
					}
				}




	});



	/* menu listeners */

	container.addEventListener('click', function () {
		if (!menu_wrapper.classList.contains('visible')) {
			menu_wrapper.classList.add('visible');
			menu_share_button.innerHTML = 'Copy Link';
		}
	});

	container.addEventListener('contextmenu', function (ev) {
		ev.preventDefault();
		if (!menu_wrapper.classList.contains('visible')) {
			menu_wrapper.classList.add('visible');
			menu_share_button.innerHTML = 'Copy Link';
		}
		return false;
	}, false);

	menu_close_button.addEventListener('click', function () {
		menu_wrapper.classList.remove('visible');
	});

	menu_mute_button.addEventListener('click', function () {
		if (menu_mute_button.classList.contains('active')) {
			menu_mute_button.classList.remove('active');
			volume_level = 0;

			/* un-/mute and play/pause videos and audios */
			for (var i = audio_video_el_array.length - 1; i >= 0; i--) {
				audio_video_el_array[i].volume = volume_level;
				audio_video_el_array[i].muted = true;
				if (audio_video_el_array[i].paused) {
					audio_video_el_array[i].play();
				} else {
					audio_video_el_array[i].pause();
				}
			}

			//playlist_heading_note.classList.add('visible'); // show (muted)
		} else {
			menu_mute_button.classList.add('active');
			volume_level = 0.666;

			/* un-/mute and play/pause videos and audios */
			for (var i = audio_video_el_array.length - 1; i >= 0; i--) {
				audio_video_el_array[i].volume = volume_level;
				audio_video_el_array[i].muted = false;
				if (audio_video_el_array[i].paused) {
					audio_video_el_array[i].play();
				} else {
					audio_video_el_array[i].pause();
				}
			}

			//playlist_heading_note.classList.remove('visible');
		}
	});

	menu_autoscroll_button.addEventListener('click', function () {
		if (menu_autoscroll_button.classList.contains('active')) {
			menu_autoscroll_button.classList.remove('active');
			scrollStop();
			body.style.overflowY = 'auto';
		} else {
			menu_autoscroll_button.classList.add('active');
			scrollStart();
			body.style.overflowY = 'hidden';
		}
	});

	menu_reload_button.addEventListener('click', function () {
		request_worker.terminate();
		body.style.opacity = 0;
		for (var i = audio_video_el_array.length - 1; i >= 0; i--) {
			audio_video_el_array[i].pause();
		}
		//window.location.reload(true); is done via href
	});

	menu_share_button.addEventListener('click', function () {
		copy(window.location.href);
		setTimeout(()=>{
			menu_share_button.innerHTML = 'Copied Link';
		}, 200);
	});

	window.addEventListener('keydown', function (ev) {
    if (ev.key == 'Escape'|| ev.key == 'Esc'|| ev.keyCode == 27) {
        ev.preventDefault();
        if (menu_wrapper.classList.contains('visible')) {
					menu_wrapper.style.opacity = 0.75;
				}
        return false;
    }
	}, true);

	window.addEventListener('keyup', function (ev) {
    if (ev.key == 'Escape'|| ev.key == 'Esc'|| ev.keyCode == 27) {
        ev.preventDefault();
        if (menu_wrapper.classList.contains('visible')) {
					menu_wrapper.style.opacity = '';
					menu_wrapper.classList.remove('visible');
				}
        return false;
    }
	}, true);


	body.addEventListener('touchend', function () {
		/* play and unmute videos and audios */
		for (var i = audio_video_el_array.length - 1; i >= 0; i--) {
			if (audio_video_el_array[i].readyState === 4 && audio_video_el_array[i].paused) {
				if (menu_mute_button.classList.contains('active')) {
					audio_video_el_array[i].muted = true;
					audio_video_el_array[i].volume = 0; // embeds are often more silent
				} else {
					audio_video_el_array[i].muted = false;
					audio_video_el_array[i].volume = 0.666; // embeds are often more silent
				}

				audio_video_el_array[i].play();
				let filename = audio_video_el_array[i].src.split('/');
				filename = filename[filename.length - 1];
				if (!audio_video_filname_array.includes(filename) && !audio_video_el_array[i].paused) {
					audio_video_filname_array.push(filename);
					let list_element = document.createElement('LI');
					list_element.innerHTML = filename;
					playlist.appendChild(list_element);
					playlist.parentElement.style.opacity = 1;
					setTimeout(() => {
						playlist.parentElement.style.opacity = 0;
					}, 5000 + audio_video_filname_array.length*100);
				}
			}
		}
	});


	function closeMenuOnTouchmove (ev) {
		if (menu_wrapper.classList.contains('visible')) {
				menu_wrapper.classList.remove('visible');
		}
	}
	let d_closeMenuOnTouchmove = debounce(closeMenuOnTouchmove, 300);
	menu_wrapper.addEventListener('touchmove', d_closeMenuOnTouchmove);



	function updateText (target, content, rangeX, rangeY, timeout) {
		target.style.transform = 'translate('+rangeX+'%, '+rangeY+'%)';
		setTimeout(function () {
			target.innerHTML = content;
		}, timeout);
		setTimeout(function () {
			/*if (target === heading) {
				target.style.transform = 'translate(0%, 100%)';
			} else {*/
				target.style.transform = 'translate(0%, 0%)';
			//}
		}, (timeout + timeout));

		//console.log(content);
	}


	function scroll_1 () {
		updateText(heading, all_text_array[Math.floor(Math.random() * all_text_array.length)], 0, -150, 500);
		updateText(paragraphs[0], all_text_array[Math.floor(Math.random() * all_text_array.length)], -300, 150*6, Math.floor((Math.random() * 1000) + 500));
		updateText(paragraphs[1], all_text_array[Math.floor(Math.random() * all_text_array.length)]+'<br>'+all_text_array[Math.floor(Math.random() * all_text_array.length)], 0, 150*4, Math.floor((Math.random() * 1000) + 500));
		updateText(paragraphs[2], all_text_array[Math.floor(Math.random() * all_text_array.length)], 300, 150*2, Math.floor((Math.random() * 1000) + 500));
		/*
			updateText(heading, all_text_array[Math.floor(Math.random() * all_text_array.length)], 0, -100, 500);
			updateText(paragraphs[0], all_text_array[Math.floor(Math.random() * all_text_array.length)], -100, 300, Math.floor((Math.random() * 1000) + 500));
			updateText(paragraphs[1], all_text_array[Math.floor(Math.random() * all_text_array.length)]+'<br>'+all_text_array[Math.floor(Math.random() * all_text_array.length)], 0, 300, Math.floor((Math.random() * 1000) + 500));
			updateText(paragraphs[2], all_text_array[Math.floor(Math.random() * all_text_array.length)], 100, 300, Math.floor((Math.random() * 1000) + 500));
		}*/

		/* play and unmute videos and audios */
		for (var i = audio_video_el_array.length - 1; i >= 0; i--) {
			if (audio_video_el_array[i].readyState === 4 && audio_video_el_array[i].paused) {
				audio_video_el_array[i].muted = false;
				audio_video_el_array[i].volume = volume_level; // embeds are often more silent
				audio_video_el_array[i].play();
				let filename = audio_video_el_array[i].src.split('/');
				filename = filename[filename.length - 1];
				if (!audio_video_filname_array.includes(filename) && !audio_video_el_array[i].paused) {
					audio_video_filname_array.push(filename);
					let list_element = document.createElement('LI');
					list_element.innerHTML = filename;
					playlist.appendChild(list_element);
					playlist.parentElement.style.opacity = 1;
					setTimeout(() => {
						playlist.parentElement.style.opacity = 0;
					}, 5000 + audio_video_filname_array.length*100);
				}
			}
		}
	}

	function scroll_1_touch () {
		updateText(heading, all_text_array[Math.floor(Math.random() * all_text_array.length)], 0, -150, 500);
		updateText(paragraphs[0], all_text_array[Math.floor(Math.random() * all_text_array.length)], -300, 150*6, Math.floor((Math.random() * 1000) + 500));
		updateText(paragraphs[1], all_text_array[Math.floor(Math.random() * all_text_array.length)]+'<br>'+all_text_array[Math.floor(Math.random() * all_text_array.length)], 0, 150*4, Math.floor((Math.random() * 1000) + 500));
		updateText(paragraphs[2], all_text_array[Math.floor(Math.random() * all_text_array.length)], 300, 150*2, Math.floor((Math.random() * 1000) + 500));
	}

	function scroll_2 () {
		// update background-image
		background.style.backgroundImage = 'url('+ image_src_array[Math.floor(Math.random() * image_src_array.length)] +')';
		//console.log(image_src_array[Math.floor(Math.random() * image_src_array.length)]);
		// update favicon
		favicon.href = image_src_array[Math.floor(Math.random() * image_src_array.length)];
		// update page-title
		page_title.innerHTML = (titles_array[Math.floor(Math.random() * titles_array.length)].length > 0) ? titles_array[Math.floor(Math.random() * titles_array.length)].replace('<b>','').replace('</b>','') : 'Really Simple Satisfaction';
		// update og:image
		og_image.content = image_src_array[Math.floor(Math.random() * image_src_array.length)];
		// update og:description
		og_description.content = (titles_array[Math.floor(Math.random() * titles_array.length)].length > 0) ? titles_array[Math.floor(Math.random() * titles_array.length)].replace('<b>','').replace('</b>','') : 'Really Simple Satisfaction is monitoring and curating fresh content for you. Keep up with all the topics and trends that matter right now – all in one place. Be in the know.';

		// update scroll-index top and bottom
		scroll_index_h[0].style.width = 100 * (window.innerHeight + window.scrollY) / document.body.offsetHeight + 'vw';
		scroll_index_h[1].style.width = 100 * (window.innerHeight + window.scrollY) / document.body.offsetHeight + 'vw';


		if ((window.innerHeight * 4 + window.scrollY) < document.body.offsetHeight) {
			// everything is good
		} else if (media_el_array.length <= 0 && (window.innerHeight + window.scrollY) <= document.body.offsetHeight) {
				// scroll to top if there is not data anymore and user has scrolled to bottom
				document.documentElement.scrollTop = 0;
		} else {
			let length;
			if (media_el_array.length >= 25) {
				length = 25;
			} else {
				length = media_el_array.length;
			}
			for (var i = length - 1; i >= 0; i--) {
				const r = Math.floor(Math.random() * Math.floor(media_el_array.length - 1));
				container.appendChild(media_el_array[r]);

				media_el_array.splice(media_el_array[r], 1);
				//console.log('media_el_array.length: ', media_el_array.length);
			}
		}

		resize();
	}


	let l_scroll_1 = (Modernizr.touchevents) ? throttle(scroll_1_touch, 5000) : throttle(scroll_1, 5000); // time used for autocrolling one screen-height and staying there for a moment
	let l_scroll_2 = throttle(scroll_2, 200);

	window.addEventListener('scroll', function () {
		l_scroll_1();
		l_scroll_2();
		//throttle(resetHeight, 200)
	});

	/*function resetHeight () {
		let vpH = window.innerHeight;
		scroll_index_h[0].style.bottom = vpH  + 'px';
		scroll_index_h[0].style.top = 'auto';
		heading.style.bottom = vpH + 'px';
		heading.style.top = 'auto';
	}

	resetHeight();
	window.addEventListener('resize', throttle(resetHeight, 200));*/


	ip_worker.addEventListener('message', (ev) => {
		const data = ev.data;

		// close ip-worker
		ip_worker.terminate();

		setTimeout(function () {
			shadow_bar_bottom.style.boxShadow = 'inset 0 -25vh 25vh -20vh rgba('+data.rgbValue[0]+','+data.rgbValue[1]+','+data.rgbValue[2]+',0.8)';
			shadow_bar_top.style.boxShadow = 'inset 0 25vh 25vh -20vh rgba('+data.rgbValue[0]+','+data.rgbValue[1]+','+data.rgbValue[2]+',0.8)';

			heading.style.textShadow = '0 0 1vw rgb('+data.rgbValue[0]+', '+data.rgbValue[1]+', '+data.rgbValue[2]+')';
			//heading.style.boxShadow = '0 0 1vh .1vh rgba('+data.rgbValue[0]+', '+data.rgbValue[1]+', '+data.rgbValue[2]+', 0.5)';
			paragraphs[0].style.backgroundColor = 'rgba('+data.rgbValue[0]+', '+data.rgbValue[1]+', '+data.rgbValue[2]+',0.8)';
			paragraphs[1].style.textShadow = '0 0 0.5vw rgb('+data.rgbValue[0]+', '+data.rgbValue[1]+', '+data.rgbValue[2]+')';
			paragraphs[1].style.boxShadow = '0 0 1vh .1vh rgba('+data.rgbValue[0]+', '+data.rgbValue[1]+', '+data.rgbValue[2]+', 0.5)';
			paragraphs[2].style.borderColor = 'rgba('+data.rgbValue[0]+', '+data.rgbValue[1]+', '+data.rgbValue[2]+',0.8)';

			scroll_index_h[0].style.width = '28vw';
			scroll_index_h[1].style.width = '28vw';
			scroll_index_h[0].style.boxShadow = '0 0 1vh .1vh rgba('+data.rgbValue[0]+', '+data.rgbValue[1]+', '+data.rgbValue[2]+', 0.75)';
			scroll_index_h[1].style.boxShadow = '0 0 1vh .1vh rgba('+data.rgbValue[0]+', '+data.rgbValue[1]+', '+data.rgbValue[2]+', 0.75)';

			loading_box.style.textShadow = '0 0 0.5vw rgb('+data.rgbValue[0]+', '+data.rgbValue[1]+', '+data.rgbValue[2]+')';
			loading_box.style.boxShadow = '0 0 1vh .1vh rgba('+data.rgbValue[0]+', '+data.rgbValue[1]+', '+data.rgbValue[2]+', 0.75)';

			//if (window.innerWidth <= 480) {
				//updateText(paragraphs[0], all_text_array[Math.floor(Math.random() * all_text_array.length)], -300, 150*6, Math.floor((Math.random() * 1000) + 500));
				updateText(paragraphs[1], starter_text_array[0], 0, 150*4, 1000);
				//updateText(paragraphs[2], all_text_array[Math.floor(Math.random() * all_text_array.length)], 300, 150*2, Math.floor((Math.random() * 1000) + 500));
			//}
		}, 5000);

		setTimeout(function () {
			//background.style.backgroundImage = 'url('+ image_src_array[Math.floor(Math.random() * image_src_array.length)] +')';
			updateText(paragraphs[1], starter_text_array[1], 0, 150*4, 1000);
			scroll_index_h[0].style.width = '72vw';
			scroll_index_h[1].style.width = '72vw';
		}, 10000);
		setTimeout(function () {
			//background.style.backgroundImage = 'url('+ image_src_array[Math.floor(Math.random() * image_src_array.length)] +')';
			updateText(paragraphs[1], starter_text_array[2], 0, 150*4, 1000);
			scroll_index_h[0].style.width = '88vw';
			scroll_index_h[1].style.width = '88vw';
		}, 15000);
		setTimeout(function () {
			//background.style.backgroundImage = 'url('+ image_src_array[Math.floor(Math.random() * image_src_array.length)] +')';
			updateText(paragraphs[1], starter_text_array[3], 0, 150*4, 1000);
			scroll_index_h[0].style.width = '96vw';
			scroll_index_h[1].style.width = '96vw';
		}, 20000);

		setTimeout(function () {
			// unload background-image
			//background.style.backgroundImage = 'none';
			//background.style.backgroundImage = 'url('+ image_src_array[Math.floor(Math.random() * image_src_array.length)] +')';

			loading_spinner.style.display = 'none';
			loading_submit.style.display = 'block';
			loading_submit.innerHTML = 'Continue';
			loading_submit.style.boxShadow = '0 0 1vh .1vh rgba('+data.rgbValue[0]+', '+data.rgbValue[1]+', '+data.rgbValue[2]+', 0.5)';
			loading_submit.style.fontSize = '2vw';

			scroll_index_h[0].style.width = '100vw';
			scroll_index_h[1].style.width = '100vw';

			//updateText(paragraphs[0], all_text_array[Math.floor(Math.random() * all_text_array.length)], -300, 150*6, Math.floor((Math.random() * 1000) + 500));
			//updateText(paragraphs[1], all_text_array[Math.floor(Math.random() * all_text_array.length)]+'<br>'+all_text_array[Math.floor(Math.random() * all_text_array.length)], 0, 150*4, Math.floor((Math.random() * 1000) + 500));
			updateText(paragraphs[1], starter_text_array[4], 0, 150*4, 1000);
			//updateText(paragraphs[2], all_text_array[Math.floor(Math.random() * all_text_array.length)], 300, 150*2, Math.floor((Math.random() * 1000) + 500));
		}, 25000);

	});

	request_worker.addEventListener('message', (ev) => {
		const data = ev.data;

		if (data.type === 'trends' && data.data.length > 0) {
			const trends_json = JSON.parse(data.data);
			for (var i = 0; i < trends_json.sub_links_array.length; i++) {
				sub_links_array.push(trends_json.sub_links_array[i]);
				searchCheckGet(trends_json.sub_links_array[i]);
			}
			for (var i = 0; i < trends_json.all_text_array.length; i++) {
				all_text_array.push(trends_json.all_text_array[i]);
				titles_array.push(trends_json.all_text_array[i]);
			}
		}

		if (data.type === 'content' && data.data.length > 0) {
			const content_json = JSON.parse(data.data);

			const json_image_src_array = content_json.image_src_array;
			for (var i = 0; i < json_image_src_array.length; i++) {
				if (json_image_src_array[i].length > 0) {
					let objImage = new Image();
					objImage.src = json_image_src_array[i];
					if (objImage.complete) {
						image_src_array.push(json_image_src_array[i]);
						//let image = document.createElement('img');
						//image.src = json_image_src_array[i];
						//console.log(json_image_src_array[i]);
						// random align images in row horizontally
						if (Math.floor(Math.random() * 2) === 0) {
							objImage.style.alignSelf = 'flex-start';
						} else if (Math.floor(Math.random() * 2) === 1) {
							objImage.style.alignSelf = 'center';
						} else {
							objImage.style.alignSelf = 'flex-end';
						}

						media_el_array.push(objImage);
					}
				}
			}

			/*const json_svg_el_array = content_json.svg_el_array;
			for (var i = 0; i < json_svg_el_array.length; i++) {
				let div = document.createElement('div');
				div.innerHTML = json_svg_el_array[i].trim();
				media_el_array.push(div.firstChild);
			}*/

			const json_iframe_src_array = content_json.iframe_src_array;
			for (var i = 0; i < json_iframe_src_array.length; i++) {
				if (json_iframe_src_array[i].length > 0) {
					let iframe = document.createElement('iframe');
					iframe.setAttribute('frameborder', 0);
					iframe.setAttribute('playsinline', true);
					iframe.setAttribute('webkit-playsinline', true);
					iframe.setAttribute('allowfullscreen', false);
					iframe.setAttribute('allowfullscreen', 0);
					iframe.src = json_iframe_src_array[i];
					media_el_array.push(iframe);
				}
			}

			const json_video_data_array = content_json.video_data_array;
			for (var i = 0; i < json_video_data_array.length; i++) {
				//console.log('v ',json_video_data_array[i]);
				let video = document.createElement('video');
				let video_src = document.createElement('source');
				video_src.src = json_video_data_array[i].src;
				video_src.type = json_video_data_array[i].type;

				video.src = json_video_data_array[i].src;
				video.setAttribute('preload', 'auto');
				video.setAttribute('autoplay', true);
				video.setAttribute('loop', true);
				video.setAttribute('controls', true);
				video.setAttribute('playsinline', true);
				video.setAttribute('webkit-playsinline', true);
				video.setAttribute('muted', false);
				video.setAttribute('allowfullscreen', false);
				video.setAttribute('allowfullscreen', 0);

				video.pause();

				video.appendChild(video_src);

				media_el_array.push(video);
				// also audio_video_el_array for scroll listeners
				audio_video_el_array.push(video);
			}

			const json_audio_data_array = content_json.audio_data_array;
			for (var i = 0; i < json_audio_data_array.length; i++) {
				//console.log('a ',json_audio_data_array[i]);
				let audio = document.createElement('audio');
				let audio_src = document.createElement('source');
				audio_src.src = json_audio_data_array[i].src;
				audio_src.type = json_audio_data_array[i].type;

				audio.src = json_audio_data_array[i].src;
				audio.setAttribute('autoplay', true);
				audio.setAttribute('loop', true);
				audio.setAttribute('controls', true);
				audio.removeAttribute('muted');

				audio.pause();

				audio.appendChild(audio_src);

				media_el_array.push(audio);
				// also audio_video_el_array for scroll listeners
				audio_video_el_array.push(audio);
			}


			const json_all_text_array = content_json.all_text_array;
			if (json_all_text_array) {
				for (var i = 0; i < json_all_text_array.length; i++) {
					if (json_all_text_array[i].length > 0) {
						all_text_array.push(json_all_text_array[i]);
					}
				}
			}

		}
	});


	function searchCheckGet (query) {
		// start worker to get pages xml-data
		request_worker.postMessage({
			'type' : 'content',
			'proxy' : CORS_PROXY,
			'url' : query // if url is null, urls from trends-array (listed in request_worker.js) are used
		});

	}

};
