/* =======================================

  Animated scrolling

  usage: animatedScroll({ to:500, duration: 500 }, function(){});

==========================================*/
//var easing = require('./easing');

function animatedScroll(options, callback = () => {}) {
  const start = Date.now();
  const el = options.el || window;
  let from = window.pageYOffset;

  if (options.el) {
    from = el.scrollTop;
  }

  const easingFunction = options.easing || easeInOutQuad;
  const to = options.to;
  const duration = options.duration || 500;

  if (from === to) {
    return callback();
  }

  function scroll() {
    const currentTime = Date.now();
    const time = Math.min(1, (currentTime - start) / duration);
    const easedT = easingFunction(time);
    const current = easedT * (to - from) + from;

    if (el === window) {
      window.scrollTo(0, current);
    } else {
      el.scrollTop = current;
    }

    if (time < 1) {
      requestAnimationFrame(scroll);
    } else {
      callback();
    }
  }
  requestAnimationFrame(scroll);
}

function easeInOutQuad(t) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}


const STEP = window.innerHeight;
let maxHeight;
let position;
let scrollTimeout;

export function scrollOnce() {
  position += STEP;
    
  animatedScroll({ to: position, duration: 1250 }, function () {
    if (isActive) {
      scrollTimeout = setTimeout(scrollOnce, 3750);
    }
  });
}

let isActive = false;
export function scrollStart () {
  isActive = true;
  position = window.scrollY;
  scrollOnce();
}

export function scrollStop () {
  isActive = false;
  clearTimeout(scrollTimeout);
}


window.addEventListener('resize', resize);
resize();

export function resize() {
  maxHeight = document.body.offsetHeight;
  position = window.scrollY;
}